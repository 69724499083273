/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
// import './theme_plugins.css';
import './bootstrap_theme_4.4.1.css';
import './theme_plugins/fontawesome/css/fontawesome.min.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import './scss/style.scss';
//import './theme_plugins/fontawesome/css/fontawesome.min.css';

global.jQuery = global.$ = require('jquery');
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// start the Stimulus application
import './bootstrap';

import Swal from 'sweetalert2'

import 'lite-youtube-embed';
import 'lite-youtube-embed/src/lite-yt-embed.css'

(function ($) {

    var $captions = $(".text-captions > *");
    animateTextCaptions($captions);

    var isContactFormSending = false;
    $("#contactsForm").submit(function(e) {
        e.preventDefault();
        if (isContactFormSending) {
            return;
        }

        isContactFormSending = true;
        if(!$('input[name="name"]').val() || !$('input[name="email"]').val() || !$('textarea[name="text"]').val()) {
            Swal.fire({
                title: 'Заполните форму',
                text: 'Необходимо заполнить поля: Имя, Email, Вопрос.',
                customClass: { container: 'swal2-sl'},
                showCloseButton: true,
            });
            isContactFormSending = false;
            return;
        }

        Swal.fire({
            title: 'Отправляем сообщение...',
            customClass: { container: 'swal2-sl'},
            didOpen: () => {
                Swal.showLoading()
            }
        });
        var form = $(this);
        var url = form.attr('action');

        grecaptcha.ready(function() {
            grecaptcha.execute(googleRecaptchaSiteKey, {action: 'submit'}).then(function(token) {
                form.find('input[name="recaptcha"]').val(token);
                $.ajax({
                    type: "POST",
                    url: url,
                    data: form.serialize(),
                    success: function(data)
                    {
                        form.each(function(){
                            this.reset();
                        });
                    },
                    complete: function(data){
                        isContactFormSending = false;
                        Swal.fire({
                            title: 'Сообщение успешно отправлено!',
                            icon: 'success',
                            customClass: { container: 'swal2-sl'},
                            showCloseButton: true,
                        });
                    }
                });
            });
        });
    });



    function animateTextCaptions($elem) {
        var $captions = $elem;
        $captions.each(function () {
            var $captionElem = $(this),
                animationDuration = "600ms";
            if ($(this).attr("data-animate-duration")) {
                animationDuration = $(this).attr("data-animate-duration") + "ms";
            }
            $captionElem.css({
                opacity: 0
            })
            $(this).css("animation-duration", animationDuration);
        })
        $captions.each(function (index) {
            var $captionElem = $(this),
                captionDelay = $captionElem.attr("data-caption-delay") || index * 350 + 1000,
                captionAnimation = $captionElem.attr("data-caption-animate") || "fadeInUp";
            var t = setTimeout(function () {
                $captionElem.css({
                    opacity: 1
                })
                $captionElem.addClass(captionAnimation)
            }, captionDelay);
        })
    }

    $('.add-name-btn').click(function() {
        $(this).fadeOut(400, function() {
            $(this).parent().find('.name-field-container-0').fadeIn(400, function() {
                const input = $(this).parent().find('input')[0];
                input.focus();
                input.setSelectionRange(input.getAttribute('value').length, input.getAttribute('value').length);
            });
        });
    })
    var nameFields = document.querySelectorAll(".name-field");
    if (nameFields.length > 0) {
        // nameFields[0].focus();
        // nameFields[0].setSelectionRange(nameFields[0].getAttribute('value').length, nameFields[0].getAttribute('value').length);
        for ( var t = 0; t < nameFields.length; t++ ) {
            nameFields[t].addEventListener("keyup", function(event){
                const link = document.getElementById(this.getAttribute('data-link-id'));
                let href = new URL(link.getAttribute('href'));
                href.searchParams.set('name', this.value);
                link.setAttribute('href', href.toString());
            }, false);
        }
    }
})(jQuery)
